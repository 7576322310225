@tailwind base;
@tailwind components;
@tailwind utilities;

.horizontal-slider {
    @apply h-8 mt-2 mx-1
}
.horizontal-slider .track {
    @apply h-2 bg-blue-100
}
.horizontal-slider .track-1 {
    @apply bg-blue-300
}
.horizontal-slider .thumb {
    @apply -top-2 bg-blue-500 text-white rounded-full w-7 h-7 text-sm text-center;
    padding-top:3px
}

